import { useState, useEffect, useRef } from 'react'
import dynamic from 'next/dynamic';

import { useModalContext } from '@/context/ModalContext'
import { useSearchModalContext } from '@/context/SearchModalContext'
import { useCustomerContext } from '@/context/CustomerContext'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import { CSSTransition } from 'react-transition-group'
import Link from 'next/link'
import IconSearch from '@/svgs/search.svg'
import IconQuestion from '@/svgs/question-circle.svg'
import IconUser from '@/svgs/user.svg'
import { useRouter } from 'next/router'
import { useMemberAccountContext } from '@/context/MemberAccountContext'
import parse from 'html-react-parser'
import { useHeaderContext } from '@/context/HeaderContext'

const FreeShippingProgress = dynamic(() => import('@/components/FreeShippingProgress/FreeShippingProgress'))
const ShippingInfoModal = dynamic(() => import('@/components/FreeShippingInfoModal/FreeShippingInfoModal'))
import { forwardRef } from 'react'

const NavigationUtilities = forwardRef(({ props, classes }, ref) => {
  const router = useRouter()
  const modalContext = useModalContext()
  const { customer, logout } = useCustomerContext()
  const { openCartFlyout } = useHeadlessCheckoutContext()
  const { data } = useHeadlessCheckoutContext()
  const { setSearchOpen } = useSearchModalContext()
  const { customerCredit } = useMemberAccountContext()

  const [showCustomerServiceInfo, setShowCustomerServiceInfo] = useState(false)
  const [showUserMenuOptions, setShowUserMenuOptions] = useState(false)
  const [customerServiceMenuHovered, setCustomerServiceMenuHovered] =
    useState(false)
  const [userMenuHovered, setUserMenuHovered] = useState(false)
  const [freeShippingProgressModalOpen, setFreeShippingProgressModalOpen] =
    useState(false)
  const faqMenuRef = useRef(null)
  const userMenuRef = useRef(null)
  const cartMenuRef = useRef(null)

  const cartTotal = parseFloat(data?.cart?.cost?.subtotalAmount?.amount || 0)
  const shippingThreshold = 125

  const navCTA = customer?.is_member ? props.memberCta : props.nonMemberCta
  const customerService = props.customerService

  const { closedDoors } = useHeaderContext()

  const openSearchModal = () => {
    if (router.pathname === '/pages/search') {
      return
    }
    setSearchOpen(true)
  }

  const openAccountModal = (e) => {
    if (customer) {
      router.push('/account/subscription')
    } else {
      e.preventDefault()
      modalContext.setIsOpen(false)
      modalContext.setModalType('login')
      modalContext.setIsOpen(true)
    }
  }

  useEffect(() => {
    setShowCustomerServiceInfo(customerServiceMenuHovered)
  }, [customerServiceMenuHovered])

  useEffect(() => {
    setShowUserMenuOptions(userMenuHovered)
  }, [userMenuHovered])

  return (
    <ul className={[classes.navItems, classes.navUtilities].join(' ')} ref={ref}>
      {!closedDoors && (
        <li className={`${classes.navItem} ${classes['sm-desktop']}`}>
          <Link href={navCTA.ctaUrl ? navCTA.ctaUrl : ''} legacyBehavior>
            <a className={[classes.navButton, 'btn', 'salmon'].join(' ')}>
              {parse(navCTA.ctaText)}
            </a>
          </Link>
        </li>
      )}
      <li className={`${classes.navItem} ${classes['sm-desktop']}`}>
        <IconSearch onClick={() => openSearchModal()} />
      </li>
      <li
        className={[
          classes.navItem,
          classes.navItemAccount,
          classes['sm-desktop'],
        ].join(' ')}
        onMouseEnter={() => setUserMenuHovered(true)}
        onMouseLeave={() => setUserMenuHovered(false)}
      >
        <>
          <button
            className={classes.navIconButton}
            aria-label="My account"
            onClick={(e) => openAccountModal(e)}
          >
            <IconUser aria-hidden="true" />
          </button>
          {customer && (
            <CSSTransition
              in={showUserMenuOptions}
              timeout={125}
              nodeRef={userMenuRef}
              unmountOnExit
              classNames={{
                enter: classes.accountMenuEnter,
                enterActive: classes.accountMenuEnterActive,
                enterDone: classes.accountMenuEnterDone,
                exit: classes.accountMenuExit,
              }}
            >
              <div ref={userMenuRef} className={classes.accountMenuModal}>
                <div className={classes.accountMenuOverlay}></div>
                <div
                  onMouseEnter={() => setUserMenuHovered(true)}
                  onMouseLeave={() => setUserMenuHovered(false)}
                  className={classes.accountMenu}
                >
                  <div className={classes.accountMenuContent}>
                    <h4
                      data-testid="customer-display-name"
                      className={classes.customerDisplayName}
                    >{`${customer.displayName}`}</h4>
                    <p>{`${customer.email}`}</p>
                    {customerCredit && (
                      <p
                        data-testid="customer-credit-display"
                        className={classes.customerCreditDisplay}
                      >{`Customer Credit: $${customerCredit.amount}`}</p>
                    )}
                    <ul>
                      <li>
                        <Link href={'/account/subscription'}>
                          Subscription
                        </Link>
                      </li>
                      <li>
                        <Link href={'/account/account-details'}>
                          Account Details
                        </Link>
                      </li>
                      <li>
                        <Link href={'/account/order-history'}>
                          Order History
                        </Link>
                      </li>
                      <li>
                        <Link href={'/account/referrals'}>Referrals</Link>
                      </li>
                      <li>
                        <a onClick={logout}>Sign Out</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </CSSTransition>
          )}
        </>
      </li>
      <li
        className={[
          classes.navItem,
          classes.navItemAccount,
          classes['lg-desktop'],
        ].join(' ')}
      >
        <button
          className={classes.navIconButton}
          aria-label="My account"
          onClick={(e) => openAccountModal(e)}
        >
          <IconUser aria-hidden="true" />
        </button>
      </li>
      <li
        onMouseEnter={() => setCustomerServiceMenuHovered(true)}
        onMouseLeave={() => setCustomerServiceMenuHovered(false)}
        className={`${classes.navItem} ${classes['sm-desktop']}`}
      >
        <IconQuestion />
        <CSSTransition
          in={customerService && showCustomerServiceInfo}
          timeout={125}
          nodeRef={faqMenuRef}
          unmountOnExit
          classNames={{
            enter: classes.customerServiceInfoEnter,
            enterActive: classes.customerServiceInfoEnterActive,
            enterDone: classes.customerServiceInfoEnterDone,
            exit: classes.customerServiceInfoExit,
          }}
        >
          <div ref={faqMenuRef} className={classes.customerServiceInfoModal}>
            <div className={classes.customerServiceInfoOverlay}></div>
            <div
              onMouseEnter={() => setCustomerServiceMenuHovered(true)}
              onMouseLeave={() => setCustomerServiceMenuHovered(false)}
              className={classes.customerServiceInfo}
            >
              <div className={classes.customerServiceInfoContent}>
                <h4>{customerService.header}</h4>
                <p>{customerService.subheader}</p>
                <ul>
                  {customerService.customerServiceNavigation.menuItems.map(
                    (item) => {
                      return (
                        <li key={item._key}>
                          <Link href={item.linkUrl ? item.linkUrl : ''}>
                            {item.linkText}
                          </Link>
                        </li>
                      )
                    },
                  )}
                </ul>
              </div>
            </div>
          </div>
        </CSSTransition>
      </li>
      <li
        onClick={() => {
          openCartFlyout()
          if (router.pathname === '/cart' || router.pathname === '/checkout') {
            return
          }
        }}
        className={classes.navItem}
        onMouseEnter={() => setFreeShippingProgressModalOpen(true)}
        onMouseLeave={() => setFreeShippingProgressModalOpen(false)}
      >
        <>
          <FreeShippingProgress
            amount={cartTotal}
            shippingThreshold={shippingThreshold}
          />
          <CSSTransition
            in={freeShippingProgressModalOpen}
            timeout={125}
            nodeRef={cartMenuRef}
            unmountOnExit
            classNames={{
              enter: classes.accountMenuEnter,
              enterActive: classes.accountMenuEnterActive,
              enterDone: classes.accountMenuEnterDone,
              exit: classes.accountMenuExit,
            }}
          >
            <ShippingInfoModal
              cartTotal={cartTotal}
              shippingThreshold={shippingThreshold}
              classes={classes}
              setFreeShippingProgressModalOpen={
                setFreeShippingProgressModalOpen
              }
              cartMenuRef={cartMenuRef}
            />
          </CSSTransition>
        </>
      </li>
    </ul>
  )
})

export default NavigationUtilities
